import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';
import {
  ComplianceAssociatedItemSummaryDTO,
  ComplianceDetailsDTO,
} from '../dto/compliance.dto';
import { ComplianceDetailsConfig } from '../config/compliance-details.config';
import { ComplianceService } from '../services/compliance.service';
import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { ComplinaceConversion } from '../conversion/compliance.conversion';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileDetailsDTO } from '../../../../shared/dto/file.dto';

@Injectable({ providedIn: 'root' })
export class ComplianceDetailsStore extends DetailsBaseStore<ComplianceDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  sanitizedPdfUrl: SafeResourceUrl;
  complianceCount: ComplianceAssociatedItemSummaryDTO = {
    compliance_requirements: 0,
    controls: 0,
    risks: 0,
    incidents: 0,
  };

  constructor(
    private complianceDetailsConfig: ComplianceDetailsConfig,
    private complianceService: ComplianceService,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private complinaceConv: ComplinaceConversion,
    private sanitizer: DomSanitizer
  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      breadcrumbs: observable,
      complianceCount: observable,
      loadFilePreview: action,
      fetchComplianceAssociatedItemSummary: action,
      convertResponseToDTO: action,
    });
  }

  override initialize(): void {
    this.tabs = this.complianceDetailsConfig.complianceTabs;
    this.breadcrumbs = this.complianceDetailsConfig.breadcrumbs;
  }
  override get service(): any {
    return this.complianceService;
  }

  loadFilePreview(): void {
    const id = this.details.id;
    const fileToken = this.details.document?.token;
    if (!fileToken) {
      return;
    }
    const url = this.service.getPdfPreview(id, fileToken);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fetchComplianceAssociatedItemSummary(complianceId: number): void {
    this.service.getComplianceAssociatedItemSummary(complianceId).subscribe({
      next: (result: ComplianceAssociatedItemSummaryDTO) => {
        runInAction(() => {
          this.complianceCount = result;
        });
      },
      error: (error: any) => {
        console.error('Failed to fetch details:', error);
        runInAction(() => {});
      },
    });
  }

  convertResponseToDTO(response: any) {
    const details = this.complinaceConv.resToComplianceDetailsDTO(response);

    // Set document download URL if document and token exist
    let document: FileDetailsDTO | null = details.document;
    if (document) {
      document.downloadUrl = this.complianceService.getDownloadURL(
        details.id,
        document.token
      );
      details.document = document;
    }

    // Update breadcrumbs label
    if (details && details.title) {
      this.breadcrumbs[1].label = `${details.title}`;
    }

    return details;
  }

  override resetEntireChildState(): void {
    this.sanitizedPdfUrl = '';
    this.complianceCount = {
      compliance_requirements: 0,
      controls: 0,
      risks: 0,
      incidents: 0,
    };
  }
}
